import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import store from "../../redux/reducers/store";
import { fetchAllTrackingNumber } from "../../redux/actions/tableActions";
import { deleteTracker } from '../../redux/actions/trackDetailsAction';
import {
  getDateFromEPOC,
  getDateToEPOC,
  getJourneyDate, getPerfectEPOC,
  titleCase,
} from "../../HelperFunctions/helper";
import AddNewTracking from "../AddNew/AddNewTracking";
import Loader from "../Loader/Loader";
import NotificationToast from '../toasts/NotificationToast'
import { Button } from "react-bootstrap";
import {logoutUser} from "../../redux/actions/userActions";

export default function TrackItemList({
  searchTerm,
  isNewEntry,
  setNewEntry,
  filter,
}) {

  // const urlhistory = useHistory();

  const [tableState, setTableState] = useState({
    data: [],
    paging: { page: 1, page_size: 10 },
    pages: [],
    sort: "desc",
  });

  const [tracking_number, setTracingNumber] = useState();
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false)
  const [toastType, setToastType] = useState(null)
  const [message, setMessage] = useState(null)
  const [submessage, setSubMessage] = useState(null)
  const [confirmDelete, setConfirmDelete] = useState(null)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)

  useEffect(() => {
    if (confirmDelete === true && deleteId) {
      store
        .dispatch(deleteTracker(deleteId))
        .then((resp) => {
          if (resp.status) {
            handleClose(true);
          }
        });
      setConfirmDelete(false)
    }
    else if (confirmDelete === false) {
      setDeleteId(null)
    }
  }, [confirmDelete])

  useEffect(() => {
    setTimeout(() => {
      setShowToast(false)
      setToastType(null)
    }, 2000)
  }, [showToast])

  const handleClose = () => {
    setShow(false);
    fetchData();
  };
  const handleShow = (track) => {
    setShow(true);
    setTracingNumber(track);
  };
  const [showLoader, setLoader] = useState(false);
  const [isSearchTrackno, setisSearchTrackno] = useState(false);
  const [firstFetchData, setFirstFetchData] = useState(false);

  var itemsPerPage = [10, 20, 30, 40, 50];

  // useEffect(() => {
  //   fetchData();
  // });

  useEffect(() => {
    // alert(isNewEntry)
    fetchData();
  }, [tableState.paging, tableState.sort, searchTerm, isNewEntry, filter]);

  const fetchData = () => {
    setLoader(true);
    // console.log(filter)
    const updated_paging = { ...paging, page_number: paging.page }
    store
      .dispatch(
        fetchAllTrackingNumber({
          ...updated_paging,
          search: searchTerm,
          sort: sort,
          status: filter,
        })
      )
      .then(async (val) => {
        // console.log(val)
        setNewEntry(false);
        setLoader(false);
        if (val.data.length > 0) {
          setFirstFetchData(true);
        }
        createTrackingDetails(val.data, val.meta);
        paging.page = 1;
      })
        .catch((err)=>{
          // console.log(err)
          setShowToast(true)
          setToastType('error')
          setMessage('Your Session have expired.')
          setSubMessage('Please login again to continue')
          alert('Your Session have expired. Please login again to continue')
          logoutUser()
          window.location.reload();
        })
  };

  const changePage = (e) => {
    setTableState({
      ...tableState,
      paging: { ...paging, page: e.target.value },
    });
  };

  const changePerPageRow = (e) => {
    setTableState({
      ...tableState,
      paging: { ...paging, page_size: e.target.value },
    });
  };

  const sortBy = (e) => {
    setTableState({
      ...tableState,
      sort: e.target.value,
    });
  };

  const createTrackingDetails = (trackingData, metaData) => {
    const trackDetails = trackingData.map((val, index) => {
      const updated = { ...val };
      // updated["start_date"] = getDateToEPOC(val["updated_at"]);
      // updated["start_date"] = getDateToEPOC(val["start_date"]);

      return updated;
    });
    let page = [];

    let page_size;

    if (metaData.total <= Number(metaData.per_page)) {
      page.push(1);
    } else {
      page_size = Math.ceil(metaData.total / Number(metaData.per_page));
      // console.log(page_size)
      // page_size = metaData.last_page; //( roundValue % 10) !== 0? roundValue + 1:roundValue

      for (let i = 1; i <= page_size; i++) {
        page.push(i);
      }
    }
    setTableState({ ...tableState, data: [...trackDetails], pages: [...page] });
  };

  const { data, paging, pages, sort } = tableState;

  return (
    <div className="container-content">
      {showToast && toastType && message && <NotificationToast message={message} subMessage={submessage} type={toastType} />}
      <div className="row justify-content-end align-items-center">
        <div className="col-lg-3 col-sm-3 text-left submit-btn-font" style={{ paddingLeft: 30 }}>
          {`Page ${paging.page} of ${pages.length}`}
        </div>
        <div className="col-lg-9 col-sm-9 justify-content-end text-right d-flex align-items-center checkbox-font">
          <div className="row dropdown-container justify-content-between w-90">
            <div className="sort-col dropdown-col col-4 text-left">
              <div className="row dropdown-row align-items-center">
                <div className="col-sm-4">
                  <label className="mb-lg-0">Sort:</label>
                </div>
                <div className="col-sm-8">
                  <select
                    className="dropdown-sort nav-item-blue "
                    onChange={(e) => {
                      sortBy(e);
                    }}
                  >
                    <option className="p-0 m-0 page-option" value="desc">
                      Newest
                    </option>
                    <option className="p-0 m-0 page-option" value="asc">
                      Oldest
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="dropdown-col col-3">
              <div className="row dropdown-row text-left align-items-center">
                <div className="col-sm-4">
                  <label className="mb-lg-0">Page:</label>
                </div>

                <div className="col-sm-8">
                  <select
                    className="dropdown nav-item-blue"
                    onChange={(e) => {
                      changePage(e);
                    }}
                  >
                    {pages.length &&
                      pages.map((val, index) => {
                        return (
                          <option
                            className="p-0 m-0 page-option"
                            key={index}
                            value={val}
                          >
                            {val}
                          </option>
                        );
                      })}
                  </select>
                </div>

              </div>
            </div>
            <div className="dropdown-col col-5">
              <div className="row text-left dropdown-row align-items-center">
                <div className="col-lg-auto col-sm-12">
                  <label className="mb-lg-0">Items Per Page:</label>
                </div>
                <div className="col-lg-auto col-sm-12">
                  <select
                    className="dropdown nav-item-blue "
                    name="Page_no"
                    id="Page_no"
                    onChange={(e) => {
                      changePerPageRow(e);
                    }}
                  >
                    {itemsPerPage.length &&
                      itemsPerPage.map((val, index) => {
                        return (
                          <option key={index} value={val}>
                            {val}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showConfirmModal}>
        <Modal.Body style={{ padding: 20, paddingTop: 40, paddingBottom: 40 }}>Confirm Deletion?</Modal.Body>
        <Modal.Footer>
          <button
            style={{ padding: "15px 58px !important" }}
            onClick={() => {
              setConfirmDelete(true)
              setShowConfirmModal(false)
            }}
            className={"submit-button-padding col-lg-3 col-sm-5 small-screen noOutline btn btn-blue-shadow btn-primary btn-lg rounded-pill ml-3 float-left submit-btn-font popup-submit-btn"
            }
          >
            Yes
              </button>
          <button className="btn add-delete-btn-font pointer btn-outline-primary rounded-pill black-text" onClick={() => {
            setConfirmDelete(false)
            setShowConfirmModal(false)
          }}>
            No
            </button>

        </Modal.Footer>
      </Modal>
      <div className="col-lg-12 ">
        { (data && data.length >= 1) ?
          data.map((val, index) => {
            return (
              <div className="border mt-4" key={index}>
                <div className="container black-bg-container">
                  <div className="row align-items-center grayBGColor">
                    <div className="col-lg-3 col-sm-4 whiteText text-left journey-header collected-header">
                      Tracking Number :
                    </div>
                    <div className="col-lg-4 col-sm-4 text-left journey-date whiteText">
                      <span>{val.tracking_number}</span>
                    </div>
                    <div className="col-4 pr-0 col-lg-4 w-50 text-left whiteText">
                      <button
                        className="btn btn-md edit-button add-delete-btn-font btn-outline-secondary float-right rounded-pill  white-text"
                        onClick={() => {
                          handleShow(val.tracker_id);
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
                <div className="container p-lg-0 black-bg-container">
                  <div className="tracking-date-row pl-lg-0 row d-flex align-items-center justify-content-center text-left pt-4">
                    <div className="col-lg-3 m-0 col-sm-12 ">
                      <h6 className="card-text checkbox-font">
                        <b>{ getDateFromEPOC(getPerfectEPOC(val.start_date)) }</b>
                      </h6>
                    </div>
                    <div className="tracking-details-padding-zero col-lg-8 col-sm-12">
                      <div className="collected-header">
                        <b>{titleCase(val.status === "waiting_to_ship" ? "Waiting to ship" : val.status)}</b>{" "}
                        <p className="card-text  nav-item-font">
                          {titleCase(val.comment || "")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
          :
            <div className="alert alert-danger mt-3" role="alert">
              Data Not Found
            </div>
        }
      </div>
      {showLoader ? <Loader /> : ""}

      <Modal show={show} backdrop="static" keyboard={false} centered>
        <Modal.Body className="add-new-outer-div p-0" closeButton>
          <AddNewTracking
            setDeleteId={setDeleteId}
            setConfirmDelete={setConfirmDelete}
            confirmDelete={confirmDelete}
            setShowConfirmModal={setShowConfirmModal}
            setShowToast={setShowToast}
            setMessage={setMessage}
            setToastType={setToastType}
            closeButton={handleClose}
            trackingNumber={tracking_number ? tracking_number : 123456}
            showEdit={true}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

// tracking_details[val.tracking_details.length - 1]
// tracking_details[val.tracking_details.length - 1]
// tracking_details[val.tracking_details.length - 1]
// tracking_details[val.tracking_details.length - 1]


