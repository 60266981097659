import { SET_USER, USER_LOGOUT } from "./actionTypes";

const userReducer = (state = {}, action) => {

  const { type, payload } = action
 
  switch (type) {
    case SET_USER:
      return {
        user: { ...payload }
      }
      break;

    case USER_LOGOUT:
      // alert('her')
      return {
        user: {...payload}
      }
      break;
    default: return state
  }

}


export default userReducer