import React from "react";

const NotificationToast = ({
  message = null,
  type = "success",
  controlToast = null,
  subMessage = null,
  subErrorMessage = null,
  ...props
}) => {

  const closeToast = ()=>{
      controlToast(false)
  }

  return (
    <div
      className=""
      style={{
        minHeight: 60,
        paddingRight: 50,
        paddingLeft: 20,
        paddingBottom: 0,
        backgroundColor: type !== "error" ? "#32A67F" : "#f46a64", 
        position: "fixed",
        right: 30,
        top: 18,
        textAlign: 'left',
        zIndex: 2000,
        borderRadius: 4,
        border: type !== "error" ? "1px solid #34C38F" : "1px solid #f46a64",
        boxShadow : '2px 4px 9px -4px #00000091'
      }}
    >
      <span className="align-self-center">
        <i style={{ color: "white", fontSize: 25 }} className={type === "success" ? "mdi mdi-check" : "mdi mdi-help"} />
      </span>
      <p
        className="align-self-center font-weight-bold"
        style={{ marginLeft: 10, fontSize: 14, color: "white", marginTop: 18, marginBottom : 0 }}
      >
        {message ? message : type === 'success' ? "Success" : message}
      </p>
        {subMessage && subMessage !== null ? 
        <p
        className="align-self-center small font-italic"  style={{ marginLeft: 10, color: "white", marginTop: 0}}>
          {subMessage}
        </p>
         : ''
        }


        {subErrorMessage &&subErrorMessage !== null ? 
        <pre
        className="align-self-center small font-italic"  style={{ marginLeft: 10, color: "white", marginTop: 0, paddingTop: 10, borderTop: 'dashed 1px #fff'}}>
          {subErrorMessage}
        </pre>
         : ''
        }
      <span className="toast-close-btn" onClick={closeToast}>&times;</span>
    </div>
  );
};

export default NotificationToast;
