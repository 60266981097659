import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { Navbar, Nav } from 'react-bootstrap'
import { NavLink, useHistory } from 'react-router-dom'
//import logo from "../assets/images/logo.png"
import logo from '../assets/images/nli-logo.svg'
import logout_icon from "../assets/images/sign-in-alt.png"
import reset_icon from "../assets/images/key.png"
import store from "../redux/reducers/store.js"
import "../assets/style/common.scss"
import { USER_LOGOUT } from '../redux/reducers/actionTypes';
import NotificationToast from './toasts/NotificationToast'

import Modal from 'react-bootstrap/Modal'

import AddNewTracking from './AddNew/AddNewTracking';
import { importTrackers, uploadFile } from '../redux/actions/trackDetailsAction';
import Loader from "./Loader/Loader";

const Menubar = ({setNewEntry}) => {

  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false)
  const [toastType, setToastType] = useState(null)
  const [message, setMessage] = useState(null)
  const [submessage, setSubMessage] = useState(null)
  const [suberrormessage, setSubErrorMessage] = useState(null)
  const [showLoader, setLoader] = useState(false);
  const urlHistory = useHistory();
  const handleClose = () => { setShow(false);setNewEntry(true)};
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const logout =
    () => {
      // alert('hasb')
      dispatch({ type: USER_LOGOUT, payload: '' })
      let userState = store.getState();
      if (!userState.user.token) {
        localStorage.removeItem('user')
      }
    }

    const openFile = () => {
      let fileUpload = document.getElementById("fileInput");
      fileUpload.click();

    };
  
    const setFile = (file) => {
      const formData = new FormData();

      formData.append("file", file);
        setLoader(true);
       store.dispatch(importTrackers(formData))
        .then((res) => {
            setLoader(false)
         setShowToast(true)
         if(res.data.error_msg.length > 1){
            setToastType('error');
         }
         else {
           setToastType('success');
         }
         setMessage(res.message)
         setSubMessage(res.data.success_count)
         setSubErrorMessage(res.data.error_msg)
         document.getElementById("fileInput").value = ''
         //setfileName(res.data.proof_of_delivery)
       })
       .catch(err => {
         setShowToast(true)
         setToastType('error')
       })
    }

    const testToast = () => {
        setShowToast(true)
        setToastType('success')
        setMessage('Testing toast')
    }

    // useEffect(() => {
    //   setTimeout(() => {
    //     // setShowToast(true)
    //     // setToastType(null)
    //   }, 2000)
    // }, [showToast])

  return (
    <div className="container-content">
        {(showLoader) ?  <Loader/> : '' }
      {showToast && toastType && <NotificationToast message={message} subMessage = {submessage} subErrorMessage = {suberrormessage} type={toastType} controlToast = {setShowToast} /> }
      <Navbar className="fixed-top" expand="lg">
        <Navbar.Brand href="#home"><img src={logo} height="78" width="170" alt="nli-logo"></img></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav " />
        <Navbar.Collapse id="basic-navbar-nav" className="">
          <Nav className="align-items-center  w-100">
            <div className="col-lg-4">
              <NavLink to="/home" className="col-lg-2 nav-item-font nav-item-blue"><strong>Home</strong></NavLink>
              <NavLink to="#" className="col-lg-2 nav-item-font nav-item-black" onClick={openFile}>Import Excel</NavLink>
            </div>
            <div className="col-lg-8 col-sm-12 col-md-12">
              <NavLink to="#" className="offset-lg-3 col-lg-2  nav-item-font nav-item-black" onClick={logout}><img src={logout_icon} height="12px" width="15px" className="mr-3" alt="logout"></img>Log Out</NavLink>
             {/* <NavLink to="/reset" className="col-lg-3 nav-item-font nav-item-black"><img src={reset_icon} height="12px" width="13px" className="mr-3" alt="reset"></img>Reset Password</NavLink>*/}
              <button className="col-lg-3 small-screen noOutline btn p-3 btn-blue-shadow btn-primary btn-lg rounded-pill ml-3" onClick={handleShow} style={{fontSize: 14}}>Add New</button>
            </div>

          </Nav>
        </Navbar.Collapse>
      </Navbar>


      <input type="file" id="fileInput" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv" hidden={true} onChange={(e) => { setFile(e.target.files[0]) }} />

      <Modal show={show} onHide={handleClose} backdrop="static" centered
        keyboard={false} >
        <Modal.Body className="add-new-outer-div p-0" closeButton><AddNewTracking setShowToast={setShowToast}
            setMessage={setMessage}
            setToastType={setToastType}
            closeButton={handleClose} /></Modal.Body>
      </Modal>
    </div>

  );
}

export default Menubar;