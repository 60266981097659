import React from "react";
import { titleCase } from "../../HelperFunctions/helper";

export default function Status({ time, status, comment, formattedDate }) {
  return (
    <div className="container">
      <div className="row pl-3 d-flex align-items-center justify-content-center text-left responsive-status pt-4">
        <div className="col-lg-3 p-0 m-0 col-sm-12 ">
          <h6 className="card-text collected-header">
            <b>{time}</b>
          </h6>
        </div>
        <div className="col-lg-8 p-0 m-0 col-sm-12">
         <div className="responsive-location">
             {/*<p className="card-text  collected-header">
             {titleCase(status)}
             </p>*/}
           {comment} : {formattedDate}</div>
        </div>
      </div>
    </div>
  );
}
