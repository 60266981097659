import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import "../../assets/style/common.scss";

export default function ResetPassword() {
  const urlhistory = useHistory();
  const emailSchema = Yup.object({
    password: Yup.string().required("Required"),
    new_password: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  return (
    <div className="container pt-5">
      <div className="d-flex justify-content-center h-100">
        <div className="card w-60">
          <div className="card-header">
            <h3>Reset Password</h3>
          </div>
          <div className="card-body">
            <Formik
              initialValues={{ password: "", new_password: '', email: '' }}
              validationSchema={emailSchema}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                  urlhistory.push("/home")
                }, 400);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="d-flex justify-content-start">
                    <label className="form-label-text" style={{ marginBottom: 0 }}>Current Password:</label>
                  </div>
                  <Field
                    className="form-control mt-3"
                    placeholder="Enter current password"
                    type="text"
                    name="password"

                  />
                  <ErrorMessage
                    className="error"
                    name="password"
                    component="div"
                    style={{ marginTop: 5 }}
                  />
                  <div className="d-flex justify-content-start" style={{ width: "100%", marginTop: 45 }}>
                    <label className="form-label-text" style={{ marginBottom: 0 }}>New Password:</label>
                  </div>
                  <Field
                    className="form-control mt-3"
                    placeholder="Enter new password"
                    type="text"
                    name="new_password"
                  />
                  <ErrorMessage
                    className="error"
                    name="email"
                    component="div"
                    style={{ marginTop: 5 }}
                  />
                  <div className="d-flex justify-content-start" style={{ width: "100%", marginTop: 45 }}>
                    <label className="form-label-text" style={{ marginBottom: 0 }}>Email:</label>
                  </div>
                  <Field
                    className="form-control mt-3"
                    placeholder="Enter your email"
                    type="email"
                    name="email"
                    validate={validateEmail}
                  />
                  <ErrorMessage
                    className="error"
                    name="email"
                    component="div"
                    style={{ marginTop: 5 }}
                  />
                  <div className="d-flex justify-content-center" style={{ width: "100%" }}>
                    <button
                      style={{ marginTop: 30 }}
                      disabled={isSubmitting}
                      className={
                        "submit-button-padding small-screen noOutline btn p-3 btn-blue-shadow btn-primary btn-lg rounded-pill submit-btn-font popup-submit-btn "
                      }
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
