import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import "../assets/style/common.scss";

export default function ForgotPasswordPage() {
  const urlhistory = useHistory();
  const emailSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Required"),
  });
  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  const redirectToLogin = () => {
    urlhistory.push("/login");
  };
  const redirectToSignup = () => {
    urlhistory.push("/register");
  };
  return (
    <div className="container pt-5">
      <div className="d-flex justify-content-center h-100">
        <div className="card w-60">
          <div className="card-header">
            <h3>Forgot Password</h3>
          </div>
          <div className="card-body">
            <Formik
              initialValues={{ email: "" }}
              validationSchema={emailSchema}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  // alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field
                    className="form-control mt-3"
                    placeholder="Email"
                    type="email"
                    name="email"
                    validate={validateEmail}
                  />
                  <ErrorMessage
                    className="error"
                    name="email"
                    component="div"
                  />
                  <button
                    className="mt-5 btn btn-block btn-primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Retrive Password
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-center">
              Already have an account?
              <a href="javascript:void(0)" onClick={redirectToLogin}>
                Sign In
              </a>
            </div>
            <div className="d-flex justify-content-center">
              <a href="javascript:void(0)" onClick={redirectToSignup}>
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
