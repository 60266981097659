import React from 'react'
import ResetPassword from '../components/ResetPassword/ResetPassword'


export default function ResetPasswordPage() {
    return (
        <div>
            <ResetPassword/>
        </div>
    )
}
