import React, { useState, useEffect } from "react";
import SearchTracking from "../components/AdminHome/SearchTracking";
import Menubar from "../components/Menubar";
import {
  getQueryParams,
} from "../HelperFunctions/helper";
import { search } from "../redux/actions/tableActions";
import "../assets/style/common.scss";
import store from "../redux/reducers/store";
import TrackItemList from "../components/AdminHome/TrackItemList";
import Loader from "../components/Loader/Loader";


export default function AdminHomePage({ location }) {
  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingDetails, settrackingDetails] = useState([]);
  const [isNewEntry, setNewEntry] = useState(false);
  const [showError, setError] = useState(false);
  const [status,setStatus]=useState([])

  const setFilter=(checked)=>{
    if(checked){
        let status=['delivered'];
        setStatus([...status]);
    }else{
      setStatus([])
    }
  }

  useEffect(() => {
    if (location.search) {
      let queryParams = getQueryParams(location.search.slice(1));
      if (queryParams.tracking) {
        setTrackingNumber(queryParams.tracking)
      }
    }else {
      setTrackingNumber(null)
    }
    
  }, [location.search]);

 
  return (
    <React.Fragment>
      <Menubar setNewEntry={setNewEntry}/>
      <div className="container admin-container margin-top ">
        <div className="d-flex justify-content-center  ">
          <div className="col-sm-12 home-blk col-lg-12 align-self-center">
              <React.Fragment>
                <SearchTracking
                  showError={showError}
                  trackDetails={trackingDetails}
                  setFilter={setFilter}
                />
              </React.Fragment>
            <div className="align-self-center mt-3">
              <TrackItemList searchTerm={trackingNumber} isNewEntry={isNewEntry} setNewEntry={setNewEntry} filter={status}/>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
