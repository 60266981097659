import { API_ENDPOINT_LOGIN, API_ENDPOINT_REGISTER, API_ENDPOINT_AUTH_LOGOUT, API_ENDPOINT_SILENT_LOGIN, } from "../../services/apiEndpoints";
import {
  USER_LOGOUT,
  USER_REGISTER,
  SET_USER_ADDRESS,

  SET_USER,

} from "../reducers/actionTypes";
import { fetchData } from "./apiRequest";
import {Redirect} from "react-router-dom";

export const getUserSession = () => dispatch => {
  let local = localStorage.getItem("user")
  if (local) {
    local = JSON.parse(local)
    dispatch(setUser(local))
  }
}

export const setUserToLocalStorage = (user, tokens) => {
  localStorage.setItem("user", JSON.stringify({ ...user, token: tokens.access_token, refresh_token: tokens.refresh_token }))
}

export const removeUserToLocalStorage = () => {
  localStorage.removeItem("user");
}

export const userLogin = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", API_ENDPOINT_LOGIN, payload))
        .then((resp) => {
          if(resp.status === false) {
            reject(resp.message)
          } 
          else {
            dispatch(setUser(resp.data, resp.meta.authorization))
            setUserToLocalStorage(resp.data, resp.meta.authorization)
            resolve(resp);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};


export const logoutUser = () => {
  // return (dispatch) => {
  //   return new Promise((resolve, reject) => {
  //     dispatch(fetchData("POST", API_ENDPOINT_AUTH_LOGOUT, ''))
  //       .then((resp) => {
  //         // dispatch(setUser(resp.data, resp.meta.authorization))
  //         // setUserToLocalStorage(resp.data, resp.meta.authorization)
  //         resolve(resp);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // };
  removeUserToLocalStorage();
}
export const fetchAccessToken = (refresh_token) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(fetchData("POST", API_ENDPOINT_SILENT_LOGIN, {
      token: refresh_token,
      grant_type: 'refresh_token',
    },
      false))
      .then((resp) => {
        dispatch(setUser(resp.data, resp.meta.authorization))
        setUserToLocalStorage(resp.data, resp.meta.authorization)
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const userRegister = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", API_ENDPOINT_REGISTER, payload))
        .then((resp) => {
          dispatch(setUser(resp.data, resp.meta.authorization))
          setUserToLocalStorage(resp.data, resp.meta.authorization)
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const setUser = (user, tokens) => {
  if (tokens) {
    const { access_token, refresh_token } = tokens
    return {
      type: SET_USER,
      payload: {
        ...user, token: access_token, refresh_token: refresh_token
      },
    };
  }
  else {
    return {
      type: SET_USER,
      payload: user
    };
  }

};


export const userLogut = () => {
 
  return {
    type: SET_USER,
    payload: {}
  };
};
export const setUserAddress = (payload) => {
  return {
    type: SET_USER_ADDRESS,
    payload: { ...payload },
  };
};
