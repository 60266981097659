import React from "react";
import logo from "../../assets/images/logo.png";
import "../../assets/style/common.scss";
export default function LogoHeader() {
  return (
    <div className="container-content">
      <img src={logo} height="85px" width="190px" alt="Card image cap" />
      <p className="col search-heading mt-4 ">
        For any further delivery  information or inquires, please contact us at our
        <br></br>
        Toll Free Number <a href="tel:1-855-813-6055">1 (855)-813-6055</a>
      </p>
    </div>
  );
}
