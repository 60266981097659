import { CloseOutlined, CloseRounded, DeleteRounded, InfoOutlined, YouTube } from "@material-ui/icons";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import "../../assets/style/common.scss";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import store from "../../redux/reducers/store";
import {
  addNewTrackDetail,
  editTrackDetail,
  deleteFile,
  uploadFile,
} from "../../redux/actions/trackDetailsAction";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ExclamationCircleIcon from '../../assets/images/exclamation-circle.svg'
import { connect } from "react-redux";
import {
  getTrackDetails,
  deleteTrackingDetails,

} from "../../redux/actions/trackDetailsAction";
import {getDateFromEPOC, getPerfectEPOC} from "../../HelperFunctions/helper";
import download from "../../assets/images/download.png"
import file from '../../assets/images/file.svg'
import deleteFileIcon from '../../assets/images/deleteFile.svg'
import deleteIcon from '../../assets/images/deleteIcon.svg'
import deleteEntryIcon from '../../assets/images/deleteEntryButton.svg'
import addIcon from '../../assets/images/addIcon.svg'
import classNames from "classnames";
import { capitalize } from "@material-ui/core";
import Loader from "../Loader/Loader";

const AddNewTracking = ({
  showEdit,
  setDeleteId,
  trackingNumber,
  setShowToast,
  setConfirmDelete,
  setMessage,
  setShowConfirmModal,
  setToastType,
  closeButton,
  showConfirmModal,
  confirmDelete,
  ...props
}) => {
  const [fileName, setfileName] = useState("");
  const [data, setData] = useState(null);
  const [fileDetails, setFileDetails] = useState(null);
const [showLoader, setLoader] = useState(false);
  useEffect(() => {
    if (trackingNumber) {
      props
        .getTrackDetails(trackingNumber)
        .then((resp) => setData(resp))
        .catch((err) => console.log("error get tracking", err));
    }
  }, [trackingNumber]);


  useEffect(() => { }, [fileName]);

  const trackingSchema = Yup.object({
    tracking_number: Yup.string().required("Add a tracking number"),
    track_details: Yup.array()
      .of(
        Yup.object().shape({
          start_date: Yup.date().required("Required"),//these constraints take precedence,
          comment: Yup.string().required('Add a comment'),
        })
      )
      .required("Must have Status Details"), //these constraints are shown if and only if inner constraints are satisfied
    file: Yup.string()
  });

  // const fileUploader=useRef();
  const openFile = () => {
    let fileUpload = document.getElementById("fileInput-pdf");
    fileUpload.click();
  };

  const setFile = (file) => {
    setFileDetails(file)
    //checking if file size is more than 5MB
    // console.log('setFile Clicked')
    if (file.size > 5 * 1024 * 1024) {
      setMessage('File size too large !')
      setShowToast(true)
      setToastType('error')
      // console.log("file too big")
      alert("File size too large! Make sure that size of file is less then 5MB.")
    }
    else {
      // console.log('adding file.')
      const formData = new FormData();
      formData.append("pod", file);
      setLoader(true);
      store.dispatch(uploadFile(trackingNumber, formData)).then((res) => {
        setfileName(res.data.proof_of_delivery)
        setData({ ...data, proof_of_delivery: res.data.proof_of_delivery })
        setLoader(false)
      })
      // setfileName(fileName)
    }
  }
  const convertDateToEPOC = (date) => {
    return new Date(date).getTime();
  };

  const getCurrentDate = () => {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    // today = yyyy + "-" + mm + "-" + dd;
    today = dd + "-" + mm + "-" + yyyy;

    return today;
  };

  const downloadFile = () => {
    if (fileName || data.proof_of_delivery) {
      // window.loc ation.href=fileName;
      window.open(data.proof_of_delivery, 'blank')
    }
  }

  const deleteEntry = (id) => {
    setShowConfirmModal(true)
    setDeleteId(id)
  }

  const removeFile = () => {
    setLoader(true)
    document.getElementById('fileInput-pdf').value = ''
    store
      .dispatch(deleteFile(trackingNumber))
      .then((resp) => {
        setfileName(null)
        setData({ ...data, proof_of_delivery: "" })
        setLoader(false)
      });
  }

  const download = (file) => {
    window.open(file)
  }

  const formatFilename = (name) => {
    const splitted = name.split("/")
    const filename = splitted[splitted.length - 1].trim()
    return filename
  }

  const capitalize = (text) => {
    if (text === "waiting_to_ship") {
      return "Waiting to ship"
    }
    if (text) {
      return text.charAt(0).toUpperCase() + text.substring(1)
    }
    else return text
  }

  return (
    <div className="container-content">
      {(showLoader) ?  <Loader/> : '' }
      <Formik
        enableReinitialize={true}
        initialValues={
          data
            ? {
              tracking_number: data.tracking_number,
              track_details: data.tracking_details.map((val) => {
                return {
                  ...val,
                  start_date: getDateFromEPOC(getPerfectEPOC(val.start_date)),
                  id: val.id,
                };
              }),
              archived: false,
              file: ""
            }
            : {
              tracking_number: "",
              track_details: [{ start_date: '', comment: "" }],
              archived: false,
              file: ""
            }
        }
        validationSchema={trackingSchema}
        onSubmit={(values, { setSubmitting }) => {
          //const shouldSubmit = values.track_details[values.track_details.length-1].status !== "delivered" ? true : (fileName !== "" || (data && data.proof_of_delivery)) ? true : false
          const shouldSubmit = true
          if (shouldSubmit) {
            setTimeout(() => {
              const updatedTrackDetails = values.track_details.map((element, index) => {
                let epoc = element.start_date;
                // let epoc = convertDateToEPOC(element.start_date);
                return {
                  ...element,
                  start_date: epoc,
                }
              });
              let requestObj = {
                tracking_number: values.tracking_number,
                tracking_details: [...updatedTrackDetails]
              };

              if (showEdit) {
                store
                  .dispatch(editTrackDetail(requestObj, trackingNumber))
                  .then((resp) => {
                    if (resp.status) {
                      closeButton(true);
                      // urlHistory.push("/home");
                    }
                  }).catch(err => console.log("err", err))
              } else {
                store.dispatch(addNewTrackDetail(requestObj)).then((resp) => {
                  if (resp.status === false) {
                    setMessage(resp.message)
                    setShowToast(true)
                    setToastType('error')
                  }
                  if (resp.status) {
                    closeButton(true);

                    // urlHistory.push("/home");
                  }
                }).catch(err => console.log("err", err))
              }
              setSubmitting(false);
            }, 400);
          }

        }}
      >
        {({ isSubmitting, errors, touched, values, setFieldValue, setFieldTouched, setFieldError, handleSubmit }) => {
          return (
            <Form>
              <div className="">
                <div className="align-items-center d-flex modal-header">
                  <div className="col-lg-10 p-0 m-0  align-items-center d-flex text-left journey-date white-text">
                    <div style={{ paddingLeft: 5 }} className="whiteText pr-3 text-left mr-3 collected-header">
                      Tracking Number:
                  </div>
                    <div className="input-container d-flex justify-content-between" style={{ 'borderColor': errors.tracking_number && touched && touched.tracking_number ? '#EB5757' : '#c4c4c4' }}>
                      <Field
                        disabled={showEdit}
                        className="input-style regular-style white-text"
                        placeholder="Tracking Number"
                        type="text"
                        name="tracking_number"
                      />
                      {errors.tracking_number && touched && touched.tracking_number && <span className="input-date"><img src={ExclamationCircleIcon} alt="error" /></span>}
                    </div>
                    <ErrorMessage
                      className="error pl-2"
                      name="tracking_number"
                      component="div"
                    />
                  </div>
                  <HighlightOffRoundedIcon
                    style={{ color: "white", fontSize: "33px" }}
                    onClick={closeButton}
                  />
                </div>
                <FieldArray
                  name="track_details"
                  render={(arrayHelpers) => (
                    <div className="align-items-left pt-3">
                      {/* {values.track_details.length ? <hr></hr> : ""} */}
                      {values.track_details.map((details, index) => (
                        <div className="row cal-row w-100 p-2 pl-3 d-flex align-items-center" style={{ paddingBottom: "15px important" }}
                          key={index}
                        >
                          <div className="col-lg-10 col-md-10 col-12">
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-10">
                                {/* * both these conventions do the same */}
                                <div className="input-container d-flex justify-content-between">
                                  <Field
                                    className="input-style remove-border-button input-date bold-style w-100 "
                                    placeholder="Date"
                                    type="date"
                                    max={getCurrentDate()}
                                     name={ `track_details[${index}].start_date`}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-8 col-10 pt-2 pt-md-0" >
                                <div className="input-container d-flex justify-content-between" style={{ 'borderColor': errors.track_details && errors.track_details[index] && errors.track_details[index].comment && touched && touched.track_details && touched.track_details[index] && touched.track_details[index].comment ? '#EB5757' : '#c4c4c4' }}>
                                  <span className="prefix-style col-auto p-0 pr-2">{"Status"}:</span>
                                  <Field className="input-style pb-1 regular-style w-100"
                                    placeholder="Enter status"
                                    name={`track_details[${index}].comment`} />
                                  {errors.track_details && errors.track_details[index] && errors.track_details[index].comment && touched && touched.track_details && touched.track_details[index] && touched.track_details[index].comment && <span className="input-date"><img src={ExclamationCircleIcon} alt="error" /></span>}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-10">
                                {/* * both these conventions do the same */}
                                <ErrorMessage
                                  className="error w-100"
                                  name={`track_details[${index}].start_date`}
                                  component="span"
                                />
                              </div>
                              <div className="col-lg-8 col-md-8 col-10 pt-2 pt-md-0" >
                                <ErrorMessage
                                  className="error w-100"
                                  name={`track_details[${index}].comment`}
                                  component="span"
                                />
                              </div>
                            </div>

                          </div>

                          {index === 0 ? (
                            ""
                          ) : (
                              <div className="col-lg-2 col-md-2 col-2">
                                <button className="remove-border-button pointer"
                                  style={{ border: 'none', backgroundColor: 'transparent' }}
                                  onClick={() => {
                                    if (data && values.track_details[index].id) {
                                      props.deleteTrackingDetails(
                                        trackingNumber,
                                        values.track_details[index].id
                                      );
                                    }

                                    arrayHelpers.remove(index);
                                  }}>
                                  <span><img style={{ width: 30, height: 30, verticalAlign: 'inherit' }} src={deleteIcon} alt="delete" /></span>
                                </button>

                              </div>
                            )}
                        </div>
                      ))}
                      {/* {values.track_details.length ? <hr></hr> : ""} */}
                      <div className="row no-column-row text-left mt-2 ml-3" style={{ marginBottom: 20 }}>
                        <button type="button" className="pointer remove-border-button" onClick={() => {
                          arrayHelpers.push({ start_date: "", status: "", comment: "" })
                          setFieldTouched(`track_details[${values.track_details.length - 1}].start_date`, false)
                          setFieldTouched(`track_details[${values.track_details.length - 1}].comment`, false)
                          setFieldTouched(`track_details[${values.track_details.length - 2}].start_date`, false)
                          setFieldTouched(`track_details[${values.track_details.length - 2}].comment`, false)
                        }
                        }>
                          <span><img style={{ width: 30, height: 30 }} src={addIcon} alt="add" /></span>
                        </button>
                      </div>
                    </div>
                  )}
                />

                <div className="checkbox-container d-none">
                  <div className="d-flex align-items-center mt-3" role="group">
                    <div className="col-lg-12 d-flex align-items-center  col-sm-12 col-md-12">
                      <div className="row check-row w-100">
                        <div className="col-sm-12 my-auto sort-txt col-lg-2 nav-item-font">
                          <b>Set To:</b>
                        </div>
                        <div className="col-auto mob-wid-col d-flex align-items-center">
                          <div className="checkbox-size" style={{ borderColor: errors.track_details && errors.track_details[values.track_details.length - 1] && errors.track_details[values.track_details.length - 1].status && touched && touched.track_details && touched.track_details[values.track_details.length - 1] && touched.track_details[values.track_details.length - 1].status ? 'red' : " #3e3e3e" }}>
                            <Field
                              placeholder=""
                              type="radio"
                              onChange={(e) => {
                                setFieldValue(`track_details[${values.track_details.length - 1}].status`, e.target.value)
                                setFieldValue('submitted', true)
                              }}
                              value="submitted"
                              name={`track_details[${values.track_details.length - 1}].status`}

                            />
                          </div>
                          <label className="checkbox-font ml-2 mt-2 ">Submitted</label>
                        </div>
                        <div className="col-auto mob-wid-col d-flex align-items-center">
                          <div className="checkbox-size" style={{ borderColor: errors.track_details && errors.track_details[values.track_details.length - 1] && errors.track_details[values.track_details.length - 1].status && touched && touched.track_details && touched.track_details[values.track_details.length - 1] && touched.track_details[values.track_details.length - 1].status ? 'red' : " #3e3e3e" }}>
                            <Field placeholder="" type="radio" name={`track_details[${values.track_details.length - 1}].status`}

                              onChange={(e) => {
                                setFieldValue(`track_details[${values.track_details.length - 1}].status`, e.target.value)
                                setFieldValue('waiting_to_ship', true)
                              }} value="waiting_to_ship" />
                          </div>
                          <label className="checkbox-font ml-2 mt-2">Waiting to Ship</label>
                        </div>

                        <div className="col-auto mob-wid-col d-flex align-items-center">
                          <div className="checkbox-size" style={{ borderColor: errors.track_details && errors.track_details[values.track_details.length - 1] && errors.track_details[values.track_details.length - 1].status && touched && touched.track_details && touched.track_details[values.track_details.length - 1] && touched.track_details[values.track_details.length - 1].status ? 'red' : " #3e3e3e" }}>
                            <Field placeholder="" type="radio" name={`track_details[${values.track_details.length - 1}].status`}
                              onChange={(e) => {
                                setFieldValue(`track_details[${values.track_details.length - 1}].status`, e.target.value)
                                setFieldValue('transit', true)

                              }} value="transit" />
                          </div>
                          <label className="checkbox-font ml-2 mt-2">Transit</label>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-lg-1 mt-0">
                    <div className="col-lg-12 d-flex align-items-center  col-sm-12 col-md-12 ">
                      <div style={{ paddingLeft: 0 }} className="offset-lg-2 col-lg-10 col-10 mob-wid-col d-flex align-items-center">
                        <div className="checkbox-size" style={{ borderColor: errors.track_details && errors.track_details[values.track_details.length - 1] && errors.track_details[values.track_details.length - 1].status && touched && touched.track_details && touched.track_details[values.track_details.length - 1] && touched.track_details[values.track_details.length - 1].status ? 'red' : " #3e3e3e" }}>
                          <Field placeholder="" type="radio" value="delivered" name={`track_details[${values.track_details.length - 1}].status`}

                            onChange={(e) => {
                              setFieldValue(`track_details[${values.track_details.length - 1}].status`, e.target.value)
                              setFieldValue('delivered', true)
                              //setFieldError('file', 'Add a file')
                            }} />
                        </div>
                        <label className="checkbox-font ml-2 mt-2">Delivered / Archive</label>
                      </div>
                    </div>
                  </div>
                  {errors.track_details && errors.track_details[values.track_details.length - 1] && errors.track_details[values.track_details.length - 1].status && touched && touched.track_details && touched.track_details[values.track_details.length - 1] && touched.track_details[values.track_details.length - 1].status &&
                    <div className="d-flex align-items-center mt-lg-1 mt-0">
                      <div className="col-lg-12 d-flex align-items-center  col-sm-12 col-md-12 ">
                        <div style={{ paddingLeft: 0 }} className="offset-lg-2 col-lg-10 col-10 mob-wid-col d-flex align-items-center">
                          <ErrorMessage
                            className="error mr-2"
                            name={`track_details[${values.track_details.length - 1}].status`}
                            component="span"
                          />
                          <span className="d-flex align-items-center"><img src={ExclamationCircleIcon} alt="error" /></span>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                {showEdit ? (
                  <div className="row cal-row align-items-center" style={{ marginTop: 30, marginLeft: 0, marginRight: 0, paddingLeft: 30 }}>
                    <span className="nav-item-font pr-3">
                      <b>Proof of Delivery:</b>
                    </span>
                    <button
                      //style={{ border: !(fileName || (data && data.proof_of_delivery)) && values.track_details && values.track_details[values.track_details.length - 1].status === "delivered" && "2px solid #EB5757" }}
                      type="button"
                      className="btn add-delete-btn-font pointer btn-outline-primary rounded-pill black-text"
                      onClick={openFile}
                    >
                      Add File
                  </button>
                    <span className="file-upload-msg">(Less than 5MB PDF only)</span>

                    {(fileName || (data && data.proof_of_delivery)) &&
                      <div className="d-flex align-items-center pl-5 mt-4" style={{ width: "100%" }}>
                        <img className="mx-3 pointer" height="20px" width="20px" src={file} alt="file" onClick={downloadFile} />
                        <label className="pointer" onClick={() => { download(data.proof_of_delivery) }} style={{ color: "#1D1D1B", marginBottom: 0, fontFamily: 'Lato' }}>{fileDetails ? fileDetails.name : formatFilename(data.proof_of_delivery)}</label>
                        {fileDetails && <label className="ml-2" style={{ marginBottom: 0, fontFamily: 'Lato', color: "#3e3e3e", fontSize: 12, alignSelf: 'flex-end' }}>({Math.round((fileDetails.size / 1024 + Number.EPSILON) * 100) / 100} KB)</label>}
                        <img className="ml-3 pointer" height="20px" width="20px" alt="delete file" src={deleteFileIcon} onClick={removeFile} />
                      </div>}
                    <input type="file" id="fileInput-pdf" accept="application/pdf" hidden={true} onChange={(e) => {
                      setFieldValue("file", e.target.files[0])
                      setFile(e.target.files[0])
                    }} />
                    {/* !(fileName || (data && data.proof_of_delivery)) && values.track_details && values.delivered && touched && touched.file &&
                      <div style={{ paddingLeft: 30 }} className="pt-2 offset-lg-2 col-lg-10 col-10 mob-wid-col d-flex align-items-center">
                        <span><label className="error pb-0 mb-0">Add a file</label></span>
                        <span className="pl-2 d-flex align-items-center"><img src={ExclamationCircleIcon} alt="error" /></span>
                      </div>
                    */}
                  </div>
                ) : (
                    ""
                  )}
                <div className="d-flex justify-content-between align-items-center" style={{ paddingBottom: 30, marginTop: 30 }}>
                  <button
                    className={
                      showEdit
                        ? "submit-button-padding col-lg-3 col-sm-5 small-screen noOutline btn btn-blue-shadow btn-primary btn-lg rounded-pill ml-3 float-left submit-btn-font popup-submit-btn "
                        : "submit-button-padding col-lg-3 col-sm-5 small-screen noOutline btn btn-blue-shadow btn-primary btn-lg rounded-pill ml-3 float-left submit-btn-font popup-submit-btn"
                    }
                    type="submit" 
                  >
                    Submit
              </button>
                  {showEdit && <div>
                    <button style={{ border: 'none', backgroundColor: "transparent" }} className="remove-border-button mr-4 float-right nav-item-blue" onClick={() => deleteEntry(trackingNumber)}>
                      <span><img src={deleteEntryIcon} alt="delete entry" /></span>
                    </button>
                  </div>}
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  );
};

export default connect(null, { getTrackDetails, deleteTrackingDetails })(
  AddNewTracking
);
