import axios from "axios";
import { fetchAccessToken, logoutUser } from "./userActions";

const Baseurl = {
	url: process.env.REACT_APP_API_URL,
};

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
	 subscribers = subscribers.filter((callback) => callback(access_token));
}

function onAccessTokenFailed() {
	subscribers.filter((callback) => callback(false));
}

function addSubscriber(callback) {
	subscribers.push(callback);
}
const instance = axios.create();

export const fetchData = (method, endpoint, data) => {

	return (dispatch, getState) => {
		const apiService = {
			getAccessToken() {
				const state = getState();
				if (!state) {
					return null;
				}
				if (!state.user || !state.user.user.token) {
					return null;
				}
				return state.user.user.token;
			},
			getRefreshToken() {
				const state = getState();
				if (!state || !state.user.user || !state.user.user.refresh_token) {
					return null;
				}
				return state.user.user.refresh_token;
			}
		}

		const user = getState();
		let requestParams = {
			url: Baseurl.url + endpoint,
			method: method
		};

		let isFormData = data instanceof FormData ? true : false;
		let headers = {
			"Content-Type": !isFormData ?
				"application/json;charset=UTF-8" :
				"multipart/form-data",
		};
		if (user && user.user && user.user.user && user.user.user.token) {
			Object.assign(headers, {
				Authorization: `Bearer ${user.user.user.token}`,
				'X-Authorization': `Bearer ${user.user.user.token}`
			});
		}
		Object.assign(requestParams, {
			headers: {
				...headers
			}
		});
		let params = isFormData ? data : JSON.stringify(data);
		if (method === "GET") {
			Object.assign(requestParams, {
				params: data,
			});
		} else if (data) {
			Object.assign(requestParams, {
				data: params,
			});
		}


		instance.interceptors.response.use(
			function (response) {
				return response;
			},
			function (error) {
				const { config, response } = error;
				const originalRequest = config;
				// const refreshTokenCookies = apiService.getRefreshToken();
				let refreshToken = apiService.getRefreshToken();

				if (response && response.status === 401 && refreshToken) {
					if (!isAlreadyFetchingAccessToken) {
						isAlreadyFetchingAccessToken = true;
						dispatch(fetchAccessToken(refreshToken))
							.then((access_token) => {
								isAlreadyFetchingAccessToken = false;
								onAccessTokenFetched(access_token);
							})
							.catch((err) => {
								isAlreadyFetchingAccessToken = false;
								
									dispatch(logoutUser());
								
								onAccessTokenFailed();
							});
					}

					const retryOriginalRequest = new Promise((resolve, reject) => {
						addSubscriber((access_token) => {
							if (!access_token) {
								reject('unauthorized access1');
							} else {
								originalRequest.headers.Authorization = `Bearer ${access_token}`;
								originalRequest.headers['X-Authorization']= `Bearer ${access_token}`;
								resolve(axios(originalRequest));
							}
						});
					});
					return retryOriginalRequest;
				}
				return Promise.reject(error);
			}
		);
		return new Promise((resolve, reject) => {
			axios(requestParams).then((resp) => {
				// alert(JSON.stringify(resp.data))
				if (resp.status) {
					resolve(resp.data);
				} else {
					reject(resp.data);
				}
			})
				.catch((error) => {
					reject(error.response);
				});
		});

	};


};


